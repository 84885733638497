import React, { useEffect } from "react"
import styled from "styled-components"

const YoutubeMessage = styled.div`
  position: absolute;
  font-size: 60px !important;
  bottom: 0;
  color: #fff;
  text-align: center;
  width: 100%;

  a {
    font-size: 60px !important;
    width: 80px;
    height: 80px;
    i {
      font-size: 60px !important;
    }
  }
`

const CourseCoordinatorMessage = props => {
  useEffect(() => {
    const { $ } = window
    //<iframe width="560" height="315" src="https://www.youtube.com/embed/sfmNkVOZlII" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    $(".popup-youtube, .popup-vimeo").magnificPopup({
      disableOn: 300,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      iframe: {
        markup: `<div className="mfp-iframe-scaler">
                      <div className="mfp-close"></div>
                      <iframe className="mfp-iframe" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>`,
        patterns: {
          youtube: {
            index: "youtube.com/", // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

            id: "v=", // String that splits URL in a two parts, second part should be %id%
            // Or null - full URL will be returned
            // Or a function that should return %id%, for example:
            // id: function(url) { return 'parsed id'; }

            src: "//www.youtube.com/embed/%id%?autoplay=1", // URL that will be set as a source for iframe.
          },
        },
      },
      srcAction: "iframe_src",
    })
  }, [])
  return (
    <section {...props}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 pt-5">
            <div className="about-img-two mr-15">
              <img
                src="/images/course_coordinator.jpg"
                alt="Image"
                style={{ border: "4px solid var(--optional-color)" }}
              />
              <YoutubeMessage>
                <a
                  href={`https://www.youtube.com/watch?v=xTe8jc3I1sI`}
                  className="popup-youtube video-btn"
                >
                  <i className="flaticon-play-button"></i>
                </a>
              </YoutubeMessage>
            </div>
          </div>
          <div className="col-lg-6 pt-5">
            <div
              className="about-content ml-15"
              style={{ textAlign: "justify" }}
            >
              <span>Message From Course Coordinator</span>
              <h3>Dr. Prabin Ch. Kalita</h3>
              <p>
                As a course coordinator of the newly established Junior College,
                "Darwin Academy, Mirza" I feel much pleasure that the college
                management committee has engaged many dedicated, committted,
                caring and innovative teachers. So, I have the farm belief that
                students studying from this institute will definitely achieve
                their dreams.
              </p>
              <p>
                I wish allround growth and development of this newborn academic
                institute.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseCoordinatorMessage
