import React from "react"
const ScholarshipParagraph = props => {
  return (
    <section {...props}>
      <div className={"container"}>
        <div className={"row"}>
          <div className="col-12">
            <h3 className="mb-20" style={{ lineHeight: 1.5 }}>
              Financial support and Scholarship for the Meritorious and
              Economically Weaker Students
            </h3>
            <p>
              Proin eget tortor risus. Mauris blandit aliquet elit, eget
              tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia
              in elementum id enim. Praesent sapien massa, convallis a
              pellentesque nec, egestas non nisi. Cras ultricies ligula sed
              magna dictum porta. Curabitur non nulla sit amet nisl tempus
              convallis quis ac lectus. Vestibulum ac diam sit amet quam
              vehicula elementum sed sit amet dui. Donec rutrum congue leo eget
              malesuada. Donec rutrum congue leo eget malesuada. Pellentesque in
              ipsum id orci.
            </p>

            <a href={"https://sites.google.com/darwinacademymirza.com/inquiry/"} className="default-btn" style={{ marginTop: 20 }}>
              Eligibility Inquiry Form
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScholarshipParagraph
