import React from "react"

const PageHeadBanner = ({ title, subtitle }) => (
  <div
    className="page-title-area bg-1"
    style={{
      backgroundImage: "url('/images/page-title-bg/page-title-bg-1.jpg')",
    }}
  >
    <div className="container">
      <div className="page-title-content">
        <h2>{title}</h2>
        <ul>
          {/* <li>
              <a href="index.html">Home</a>
            </li> */}
          <li className="active">{subtitle}</li>
        </ul>
      </div>
    </div>
  </div>
)

export default PageHeadBanner
