import React from "react"
import styled from "styled-components"

const FeeStructureSection = styled.section`
  .single-costing-card {
    h3 {
      color: #92210f !important;
    }
    .price {
      font-size: 22px;
      color: #92210f !important;
    }
  }
`

const FeeStructure = ({
  title = "Fee Structure for Higher Secondary Course",
  ...props
}) => {
  return (
    <FeeStructureSection {...props}>
      <div className={"container"}>
        {title && (
          <>
            <h3>{title}</h3>
            <p>
              We are introducing quality education at cheapest possible price
              for a bright career of the students.
            </p>
          </>
        )}
        <div
          className="row justify-content-center align-items-center"
          style={{ marginTop: 50 }}
        >
          <div className="col-lg-4 col-md-6">
            <div className="single-costing-card">
              <h3>Science Stream</h3>
              <span className="subtitle">2 Year Course</span>
              <p>
                Payment During Admission <span>(Joining Fee)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 10,000.00
                </span>{" "}
                {/* <span>(Ten Thousand Only)</span> */}
              </p>
              <p>
                First Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 8,500.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Second Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 8,500.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Third Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 8,500.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <ul>
                <li>Total</li>
                <li className="price">Rs. 35,500.00</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-costing-card">
              <h3>Arts Stream</h3>
              <span className="subtitle">2 Year Course</span>
              <p>
                Payment During Admission <span>(Joining Fee)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 8,000.00
                </span>{" "}
                {/* <span>(Ten Thousand Only)</span> */}
              </p>
              <p>
                First Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 6,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Second Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 6,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Third Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 6,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <ul>
                <li>Total</li>
                <li className="price">Rs. 26,000.00</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-costing-card">
              <h3>Commerce Stream</h3>
              <span className="subtitle">2 Year Course</span>
              <p>
                Payment During Admission <span>(Joining Fee)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 8,000.00
                </span>{" "}
                {/* <span>(Ten Thousand Only)</span> */}
              </p>
              <p>
                First Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 7,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Second Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 7,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <p>
                Third Instalment <span> (4 Month)</span>{" "}
                <span className="price">
                  {" "}
                  <br /> ₹ 7,000.00
                </span>{" "}
                {/* <span>(Eight Thousand and Five Hundread Only)</span> */}
              </p>

              <ul>
                <li>Total</li>
                <li className="price">Rs. 29,000.00</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </FeeStructureSection>
  )
}

export default FeeStructure
