import React from "react"
import styled from "styled-components"
import CourseCoordinatorMessage from "../components/CourseCoordinatorMessage"
import FeeStructure from "../components/FeeStructure"
import PageHeadBanner from "../components/PageHeadBanner"
import DownloadProspectusBox from "../components/ProspectusDownloadBox"
import ScholarshipParagraph from "../components/ScholarshipParagraph"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const CourseSubjectRow = styled.div`
  div.table-row {
    display: table;

    h4 {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    div.table-column {
      border: 1px solid #afafaf;
      display: table-cell;
      vertical-align: center;
      padding: 10px;
    }
  }
`

const Courses = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"Courses"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Courses"}
        subtitle={"Courses and Fee Structure"}
      />

      <section className="about-area pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pt-5">
              <div className="about-content ml-15">
                <span>Couses and Tution Fees</span>
                <h3>Higher Secondary Course</h3>
                <p style={{ textAlign: "justify" }}>
                  With the relentless effort of a few diligent and dedicated
                  persons an institute namely “Darwin Academy” was born on 21st
                  july, 2021 at the heart of mirza . which can be regarded as
                  the “Educational Hub” of South Kamrup Established after the
                  great name of a legend of Science, Charles Darwin, “Darwin
                  Academy” bears significance. It was actually a cherished dream
                  to set up such an institute and promote it to that height so
                  that the student can equip themselves with knowledge and skill
                  in the true sense of the term.
                </p>
              </div>
            </div>
            <div
              className="col-12 col-md-6 pt-5"
              style={{ textAlign: "center" }}
            >
              <img
                src="/images/course-icon.png"
                style={{ maxHeight: 300, objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="studys-area pt-70 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-study study-f3f3f4">
                <i className="flaticon-finance"></i>
                <h3>
                  <a href="#">Science</a>
                </h3>
                <p>
                  We have popular and experienced faculties in Physics,
                  Chemistry, Maths and Biology. Our well equipped laboratories
                  are aimed at providing practical and applied science concepts
                  to students.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-study study-f3f3f4">
                <i className="flaticon-contract"></i>
                <h3>
                  <a href="#">Arts</a>
                </h3>
                <p>
                  We are educating students how literature, history, art, music,
                  religion and culture have shaped the modern world in a new way
                  with our enthusiastic and expert faculties on each discipline
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-study study-f3f3f4">
                <i className="flaticon-computer-science"></i>
                <h3>
                  <a href="#">Commerce</a>
                </h3>
                <p>
                  We aim to guide students at primary level with modern day
                  commerce, business and Economics for their future Role and
                  Contributions towards shaping India's economy.
                </p>
              </div>
            </div>
          </div>

          <DownloadProspectusBox link={siteData.prospectus_link} />
        </div>
      </section>

      <section className="pb-100" style={{ paddingLeft: 10, paddingRight: 10 }}>
        <div className="container">
          <div>
            <h3>Subjects Available under Various Discipline</h3>
          </div>
          <CourseSubjectRow className={"row"} style={{ paddingTop: 30 }}>
            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 col-md-3 table-column"}>
                  <h4>Core Subjects</h4>
                </div>
                <div className={"col-12 col-md-3 table-column"}>English</div>
                <div className={"col-12 col-md-3 table-column"}>
                  MIL (Assamese) or Alternative English
                </div>
                <div className={"col-12 col-md-3 table-column"}>
                  Environmental Studies
                </div>
              </div>
            </div>

            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 table-column"}>
                  <h4>
                    Subjects available Under Science Stream (in both Assamese
                    and English Medium)
                  </h4>
                </div>
              </div>
            </div>
            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 col-md-3 table-column"}>Physics</div>
                <div className={"col-12 col-md-3 table-column"}>Chemistry</div>
                <div className={"col-12 col-md-3 table-column"}>
                  Mathematics
                </div>
                <div className={"col-12 col-md-3 table-column"}>Biology</div>
              </div>
            </div>

            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 table-column"}>
                  <h4>
                    Subjects available Under Arts Stream (in both Assamese and
                    English Medium)
                  </h4>
                </div>
              </div>
            </div>
            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 col-md-3 table-column"}>Economics</div>
                <div className={"col-12 col-md-3 table-column"}>
                  Political Science
                </div>
                <div className={"col-12 col-md-3 table-column"}>
                  Logic and Philosophy
                </div>
                <div className={"col-12 col-md-3 table-column"}>Education</div>
                <div className={"col-12 col-md-3 table-column"}>
                  Advance Assamese
                </div>
                <div className={"col-12 col-md-9 table-column"}>History</div>
              </div>
            </div>

            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 table-column"}>
                  <h4>
                    Subjects available Under Commerce Stream (in both Assamese
                    and English Medium)
                  </h4>
                </div>
              </div>
            </div>
            <div className={"col-12 table-row"}>
              <div className="row">
                <div className={"col-12 col-md-3 table-column"}>
                  Accountancy
                </div>
                <div className={"col-12 col-md-3 table-column"}>
                  Business Studies
                </div>
                <div className={"col-12 col-md-3 table-column"}>
                  Banking (Finance)
                </div>
                <div className={"col-12 col-md-3 table-column"}>Economics</div>
                <div className={"col-12 table-column"}>
                  Commercial Mathematics and Statistics
                </div>
              </div>
            </div>
          </CourseSubjectRow>
        </div>
      </section>

      <FeeStructure className={"pb-70"} />

      <ScholarshipParagraph />

      <CourseCoordinatorMessage className="pb-100" />
    </>
  )
})

export default Courses
