import React from "react"
const DownloadProspectusBox = ({ link = "#", heading, ...props }) => {
  return (
    <div className="course-odf-bg" {...props}>
      <div className="row align-items-center">
        <div className="col-12 col-lg-8 pt-2">
          <div className="course-pdf">
            <i className="ri-file-pdf-line"></i>
            <h4 style={{ fontSize: 20 }}>
              {heading || "View Our Prospectus to Know More"}
            </h4>
          </div>
        </div>
        <div className="col-12 col-lg-4 pt-2">
          <div className="download-pdf">
            <a href={link} className="default-btn">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadProspectusBox
